<template>
  <Breadcrumb />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form class="row g-3 form-user-edit" novalidate>
            <ul class="nav nav-tabs mb-2 mt-0" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="pills-general-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-general"
                  type="button"
                  role="tab"
                  aria-selected="true"
                >
                  <i class="bi bi-person"></i>
                  {{
                    $t(
                      "Users.GeneralSettings",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="pills-custom-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-custom"
                  type="button"
                  role="tab"
                  aria-selected="false"
                >
                  <i class="bi bi-info-circle"></i>
                  {{
                    $t(
                      "Users.CustomSettings",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </button>
              </li>
            </ul>
            <div class="tab-content">
              <div
                class="tab-pane fade active show"
                id="pills-general"
                role="tabpanel"
              >
                <div class="row row-cols-1">
                  <div class="col-md-2 text-center border">
                    <img
                      :src="require('@/assets/images/user/user-icon-lg.png')"
                      class="rounded img-fluid mt-3"
                      width="200"
                    />
                    <!-- <p class="mt-1">
                        <a>{{
                          $t(
                            "Users.ChangeProfile",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}</a>
                      </p> -->
                  </div>
                  <div class="col-md-5">
                    <label for="FirstName" class="form-label required"
                      >{{
                        $t(
                          "Users.FirstName",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="FirstName"
                      v-model="userData.firstName"
                      :disabled="true"
                      required
                    />
                    <br />
                    <label for="Email" class="form-label required"
                      >{{
                        $t(
                          "Users.Email",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="Email"
                      v-model="userData.email"
                      :disabled="true"
                      required
                    />
                    <br />
                    <label for="UserName" class="form-label"
                      >{{
                        $t(
                          "Users.UserName",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="UserName"
                      v-model="userData.userName"
                      :disabled="true"
                    />
                  </div>
                  <div class="col col-md-5">
                    <label for="LastName" class="form-label required"
                      >{{
                        $t(
                          "Users.LastName",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="LastName"
                      v-model="userData.lastName"
                      :disabled="true"
                      required
                    />
                    <br />
                    <label for="Degree" class="form-label"
                      >{{
                        $t(
                          "Users.Degree",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="Degree"
                      v-model="userData.degree"
                      :disabled="true"
                    />
                    <br />
                    <label for="GSM" class="form-label"
                      >{{
                        $t(
                          "Users.GSM",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="GSM"
                      v-model="userData.mobileNumber"
                      :disabled="true"
                    />
                  </div>
                </div>
                <br />
                <div class="row row-cols-1">
                  <div class="col col-md-4">
                    <label for="IdentityNumber" class="form-label"
                      >{{
                        $t(
                          "Users.IdentityNumber",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="IdentityNumber"
                      v-model="userData.identityNumber"
                      :disabled="true"
                    />
                    <br />
                    <label for="Department" class="form-label"
                      >{{
                        $t(
                          "Users.Department",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="Department"
                      v-model="userData.department"
                      :disabled="true"
                    />
                  </div>
                  <div class="col col-md-4">
                    <label for="BirthDate" class="form-label"
                      >{{
                        $t(
                          "Users.BirthDate",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <DatePicker
                      type="date"
                      id="BirthDate"
                      :value="userData.bornAt"
                      :displayFormat="$store.getters._dateFormat"
                      :isDisabled="true"
                    />
                    <br />
                    <label for="StartWork" class="form-label"
                      >{{
                        $t(
                          "Users.StartWork",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <DatePicker
                      type="date"
                      id="StartWork"
                      :value="userData.startedAt"
                      :displayFormat="$store.getters._dateFormat"
                      :isDisabled="true"
                    />
                  </div>
                  <div class="col col-md-4">
                    <label for="Extension" class="form-label"
                      >{{
                        $t(
                          "Users.Extension",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="Extension"
                      v-model="userData.extension"
                      :disabled="true"
                    />
                    <br />
                    <label for="DepartureWork" class="form-label"
                      >{{
                        $t(
                          "Users.DepartureWork",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <DatePicker
                      type="date"
                      id="DepartureWork"
                      :value="userData.endedAt"
                      :displayFormat="$store.getters._dateFormat"
                      :isDisabled="true"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <br />
                  <div class="form-check">
                    <label for="IsApiUser" class="form-label">{{
                      $t(
                        "Users.IsApiUser",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="IsApiUser"
                      v-model="userData.isApiUser"
                      :disabled="true"
                    />
                  </div>
                  <div class="form-check">
                    <label for="IsActive" class="form-label">{{
                      $t(
                        "BaseModelFields.IsActive",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="IsActive"
                      v-model="userData.isActive"
                      :disabled="true"
                    />
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="pills-custom" role="tabpanel">
                <div class="row row-cols-1">
                  <div class="col-md-4">
                    <div class="mb-3">
                      <label for="UserGroups" class="form-label"
                        >{{
                          $t(
                            "Users.UserGroups",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}
                      </label>
                      <FormSelect
                        type="SelectWithRemoteDataAndInternalSearch"
                        :selectedData="selectedUserGroup"
                        :isMultiple="true"
                        :isDisabled="true"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="SpecificCode1" class="form-label">{{
                        $t(
                          "Users.SpecificCode1",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="SpecificCode1"
                        v-model="userData.specificCode1"
                        :disabled="true"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="ActiveMenu" class="form-label"
                        >{{
                          $t(
                            "Users.ActiveMenu",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}
                      </label>
                      <FormSelect
                        type="SelectWithRemoteDataAndInternalSearch"
                        :selectedData="selectedMenu"
                        :isDisabled="true"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="Level2Manager" class="form-label">{{
                        $t(
                          "Users.Level2Manager",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label>
                      <FormSelect
                        type="SelectWithRemoteDataAndInternalSearch"
                        :selectedData="selectedLevel2Manager"
                        :isDisabled="true"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-3">
                      <label for="PermissionGroups" class="form-label"
                        >{{
                          $t(
                            "Users.PermissionGroups",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}
                      </label>
                      <FormSelect
                        type="SelectWithRemoteDataAndInternalSearch"
                        :selectedData="selectedPermissionGroup"
                        :isMultiple="true"
                        :isDisabled="true"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="SpecificCode2" class="form-label">{{
                        $t(
                          "Users.SpecificCode2",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="SpecificCode2"
                        v-model="userData.specificCode2"
                        :disabled="true"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="ActiveLanguage" class="form-label"
                        >{{
                          $t(
                            "Users.ActiveLanguage",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}
                      </label>
                      <FormSelect
                        type="SelectWithLocalData"
                        :selectedData="selectedActiveLanguage"
                        :data="activeLanguageData"
                        :isDisabled="true"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="Level3Manager" class="form-label">{{
                        $t(
                          "Users.Level3Manager",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label>
                      <FormSelect
                        type="SelectWithRemoteDataAndInternalSearch"
                        :selectedData="selectedLevel3Manager"
                        :isDisabled="true"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-3">
                      <label for="WelcomeUrl" class="form-label"
                        >{{
                          $t(
                            "Users.WelcomeUrl",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="WelcomeUrl"
                        v-model="userData.welcomeUrl"
                        :disabled="true"
                        placeholder="/"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="SpecificCode3" class="form-label">{{
                        $t(
                          "Users.SpecificCode3",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="SpecificCode3"
                        v-model="userData.specificCode3"
                        :disabled="true"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="Level1Manager" class="form-label">{{
                        $t(
                          "Users.Level1Manager",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label>
                      <FormSelect
                        type="SelectWithRemoteDataAndInternalSearch"
                        :isDisabled="true"
                        :selectedData="selectedLevel1Manager"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="Buddy" class="form-label">{{
                        $t(
                          "Users.Buddy",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label>
                      <FormSelect
                        type="SelectWithRemoteDataAndInternalSearch"
                        :selectedData="selectedBuddy"
                        :isDisabled="true"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "UserEdit",
  components: {},
  data() {
    return {
      userData: {},
      selectedActiveLanguage: [],
      selectedUserGroup: [],
      selectedPermissionGroup: [],
      selectedMenu: [],
      selectedLevel1Manager: [],
      selectedLevel2Manager: [],
      selectedLevel3Manager: [],
      selectedBuddy: [],
      activeLanguageData: [
        {
          key: "tr",
          value: this.$t(
            "Users.TR",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "en",
          value: this.$t(
            "Users.EN",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
    };
  },
  methods: {
    getActiveLanguage(key) {
      var text = this.$t(
        "Users.TR",
        {},
        { locale: this.$store.state.activeLang }
      );
      if (key == "tr") {
        text = this.$t(
          "Users.TR",
          {},
          { locale: this.$store.state.activeLang }
        );
      } else if (key == "en") {
        text = this.$t(
          "Users.EN",
          {},
          { locale: this.$store.state.activeLang }
        );
      }
      return text;
    },
    getUser() {
      var self = this;
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-GetUserForSuperAdmin?id={0}",
            this.$route.params.userId
          )
        )
        .then((response) => {
          self.userData = response.data.item;

          this.$root.preparePhoneNumber("GSM", self.userData.mobileNumber);

          if (response.data.item.permissionGroups != null) {
            var listPermissionIds = [];
            var listPermissionNames = [];

            if (response.data.item.permissionGroups.length > 0) {
              $.each(response.data.item.permissionGroups, function (i, v) {
                listPermissionIds.push(v.publicId);
                listPermissionNames.push(v.name);
              });
              self.userData.permissionGroupPublicIds = listPermissionIds.join(
                this.$systemSeparator
              );
            }

            if (listPermissionIds.length > 0) {
              $.each(listPermissionIds, function (i, v) {
                self.selectedPermissionGroup.push({
                  key: v,
                  value: listPermissionNames[i],
                });
              });
            }
          }
          if (response.data.item.userGroups != null) {
            var listUserGroupsIds = [];
            var listUserGroupsNames = [];

            if (response.data.item.userGroups.length > 0) {
              $.each(response.data.item.userGroups, function (i, v) {
                listUserGroupsIds.push(v.publicId);
                listUserGroupsNames.push(v.name);
              });
              self.userData.userGroupPublicIds = listUserGroupsIds.join(
                this.$systemSeparator
              );
            }

            if (listUserGroupsIds.length > 0) {
              $.each(listUserGroupsIds, function (i, v) {
                self.selectedUserGroup.push({
                  key: v,
                  value: listUserGroupsNames[i],
                });
              });
            }
          }
          if (!String.isNullOrWhiteSpace(response.data.item.activeLanguage)) {
            self.selectedActiveLanguage.push({
              key: response.data.item.activeLanguage.toString(),
              value: self.getActiveLanguage(response.data.item.activeLanguage),
            });
            self.userData.activeLanguage = response.data.item.activeLanguage;
          }
          if (!String.isNullOrWhiteSpace(response.data.item.activeMenuId)) {
            self.selectedMenu.push({
              key: response.data.item.activeMenuId.toString(),
              value: response.data.item.activeMenuName,
            });
            self.userData.activeMenuId = response.data.item.activeMenuId;
          }
          if (!String.isNullOrWhiteSpace(response.data.item.level1Manager)) {
            self.selectedLevel1Manager.push({
              key: response.data.item.level1Manager.toString(),
              value: response.data.item.level1ManagerName.toString(),
            });
            self.userData.level1ManagerPublicId =
              response.data.item.level1Manager;
          }
          if (!String.isNullOrWhiteSpace(response.data.item.level2Manager)) {
            self.selectedLevel2Manager.push({
              key: response.data.item.level2Manager.toString(),
              value: response.data.item.level2ManagerName.toString(),
            });
            self.userData.level2ManagerPublicId =
              response.data.item.level2Manager;
          }
          if (!String.isNullOrWhiteSpace(response.data.item.level3Manager)) {
            self.selectedLevel3Manager.push({
              key: response.data.item.level3Manager.toString(),
              value: response.data.item.level3ManagerName.toString(),
            });
            self.userData.level3ManagerPublicId =
              response.data.item.level3Manager;
          }
          if (!String.isNullOrWhiteSpace(response.data.item.buddy)) {
            self.selectedBuddy.push({
              key: response.data.item.buddy.toString(),
              value: response.data.item.buddyName.toString(),
            });
            self.userData.buddyPublicId = response.data.item.buddy;
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  mounted() {
    this.getUser();
  },
};
</script>
